body{
  padding-top:30px;
  background-image: none;
  background-color:#eeeeee;
}

ul.devise-links li { display: inline-block; margin-right: 10px;}

/* 固定幅レイアウト */
.container-fixed{
  width: 600px;
}

p.will_inform_soon{
  padding: 5px;
  border-color: #ff9999;
  border-width: 1px;
  border-style: solid;
  background-color: #ffe7ec;
}
